<template>
  <div class="home fixed-sn">
    <owgheader></owgheader>
    <div class="flexible-content" >
      <topnavbar />
      <main>
        <div>
          <mdb-container fluid>
            <h1>{{$t('syncwithdarwin.syncwithdarwin')}}</h1>
            <mdb-alert color="danger" v-if="error">{{error}}</mdb-alert>
            <mdb-container fluid class="text-center" v-if="importstep == 1">
              <mdb-btn v-if="!inprocessing" class="mt-4" color="primary" size="lg" v-on:click="startsync()"><mdb-icon icon="paper-plane" class="mr-1" size="1x"/> {{$t('syncwithdarwin.startsync')}}</mdb-btn>
              <mdb-btn v-else disabled class="mt-4" color="primary" size="lg" v-on:click="startsync()"><mdb-icon icon="paper-plane" class="mr-1" size="1x"/> {{$t('syncwithdarwin.newschedulesretrival')}}</mdb-btn>
            </mdb-container>
            <mdb-container fluid class="" v-if="importstep == 2">
              <mdb-alert color="info" v-if="!schedulesToImport.length">{{$t('syncwithdarwin.nonewscheduletoimport')}}</mdb-alert>
              <mdb-card v-if="schedulesToImport.length">
                <mdb-card-header color="blue darken-3">{{$t('syncwithdarwin.scheduletoimport')}}</mdb-card-header>
                <mdb-card-body>
                  <mdb-tbl btn striped>
                    <mdb-tbl-head>
                      <tr>
                        <th>{{$t('syncwithdarwin.gateways')}}</th>
                        <th>Dates</th>
                        <th>{{$t('syncwithdarwin.flightnum')}}</th>
                        <th>{{$t('syncwithdarwin.daysofoperation')}}</th>
                        <th>{{$t('syncwithdarwin.tags')}}</th>
                      </tr>
                    </mdb-tbl-head>
                    <mdb-tbl-body>
                      <tr v-for="(schedule,index) in schedulesToImport" v-bind:key="index">
                        <td>
                          <strong>
                            {{schedule.origin}} => {{schedule.destination}}
                          </strong>
                        </td>
                        <td>
                          {{formatDate(schedule.startdate)}} au {{formatDate(schedule.enddate)}}
                        </td>
                        <td>
                          {{schedule.flightnum}}
                        </td>
                        <td v-html="getDaysOfOperation(schedule)"></td>
                        <td>
                          <mdb-switch v-for="(tag,tindex) in schedule.tags" v-bind:key="tindex" :onLabel=tags[tindex] offLabel="" v-model="schedule.tags[tindex]" />
                        </td>
                      </tr>
                    </mdb-tbl-body>
                  </mdb-tbl>
                  <mdb-btn class="mt-4" color="primary" size="lg" v-on:click="importnewschedules()"><mdb-icon icon="paper-plane" class="mr-1" size="1x"/> {{$t('syncwithdarwin.importnewschedules')}}</mdb-btn>
                </mdb-card-body>
              </mdb-card>
            </mdb-container>
            <mdb-container v-if="importstep == 3">
              <h3>{{$t('syncwithdarwin.savinginprogress')}}</h3>
              <mdb-spinner big multicolor />
            </mdb-container>
          </mdb-container>
          <mdb-container v-if="importstep == 4" class="mt-5">
            <mdb-card>
              <mdb-card-header color="success-color">{{$t('syncwithdarwin.successimport')}}</mdb-card-header>
              <mdb-card-body>
                <h4>{{$t('syncwithdarwin.success')}}</h4>
                <strong>{{nbnewrecords}} {{$t('syncwithdarwin.newflightsadded')}}</strong>
              </mdb-card-body>
            </mdb-card>
          </mdb-container>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import {
  mdbBtn,
  mdbContainer,
  mdbAlert,
  mdbIcon,
  mdbCard,
  mdbCardHeader,
  mdbCardBody,
  mdbTbl,
  mdbTblHead,
  mdbTblBody,
  mdbSwitch,
  mdbSpinner,
} from 'mdbvue';
import owgheader from '@/components/Header.vue';
import topnavbar from '@/components/Topnavbar.vue';
import Apicall from '@/libs/Apicall';

export default {
  name: 'usersList',
  data() {
    return {
      dummy: '',
      error: null,
      inprocessing: false,
      importstep: 1,
      tags: [],
      schedulesToImport: [],
      nbnewrecords: 0,
    };
  },
  components: {
    owgheader,
    topnavbar,
    mdbBtn,
    mdbContainer,
    mdbAlert,
    mdbIcon,
    mdbCard,
    mdbCardHeader,
    mdbCardBody,
    mdbTbl,
    mdbTblHead,
    mdbTblBody,
    mdbSwitch,
    mdbSpinner,
  },
  methods: {
    startsync() {
      this.inprocessing = true;
      const self = this;
      const api = new Apicall();
      api.call('GET', 'api/flight/get-schedules-with-darwin').then((response) => {
        if (response.status === 'OK') {
          self.tags = response.data.tags;
          self.schedulesToImport = response.data.schedules;
          for (let i = 0; i < self.schedulesToImport.length; i += 1) {
            for (let j = 0; j < self.tags.length; j += 1) {
              // initialisation de tous les tags à false
              self.schedulesToImport[i].tags.push(false);
            }
          }

          self.importstep += 1;
          self.inprocessing = false;
        } else {
          self.error = response.msg;
        }
        self.inprocessing = false;
      });
    },
    formatDate(_thedate) {
      const thedate = new Date(_thedate);
      thedate.setHours(0, 0, 0, 0);
      const options = {
        weekday: 'short',
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      };
      return thedate.toLocaleDateString(this.$store.state.currentlang === 'fr' ? 'fr-CA' : 'en-CA', options);
    },
    getDaysOfOperation(_schedule) {
      const toreturn = [];
      if (_schedule.sunday) {
        toreturn.push(this.$t('syncwithdarwin.sunday'));
      }
      if (_schedule.monday) {
        toreturn.push(this.$t('syncwithdarwin.monday'));
      }
      if (_schedule.tuesday) {
        toreturn.push(this.$t('syncwithdarwin.tuesday'));
      }
      if (_schedule.wednesday) {
        toreturn.push(this.$t('syncwithdarwin.wednesday'));
      }
      if (_schedule.thursday) {
        toreturn.push(this.$t('syncwithdarwin.thursday'));
      }
      if (_schedule.friday) {
        toreturn.push(this.$t('syncwithdarwin.friday'));
      }
      if (_schedule.saturday) {
        toreturn.push(this.$t('syncwithdarwin.saturday'));
      }
      return toreturn.join('<br />');
    },
    importnewschedules() {
      const self = this;
      const api = new Apicall();
      self.importstep += 1;
      api.call('POST', 'api/flight/import-new-darwin-flights', { schedules: this.schedulesToImport, tags: this.tags }).then((response) => {
        if (response.status === 'OK') {
          self.importstep += 1;
          self.nbnewrecords = response.data.nbnewflights;
        } else {
          self.importstep = 999;
          self.error = response.msg;
        }
      });
    },
  },
};
</script>
